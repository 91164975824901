<template>
  <div class="home">
    <UrlShortener />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UrlShortener from '@/components/UrlShortener.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'HomeView',
  components: {
    UrlShortener,
  },
});
</script>
