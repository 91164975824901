import { Key } from '@/interfaces/Key';
import { Url } from '@/interfaces/Url';

const apiDevUrl = 'http://localhost:7027/api';
const apiProdUrl = 'https://nurdleapimanager.azure-api.net/v1/';
const headers = {
  'Ocp-Apim-Subscription-Key': '37ed30a59f9a479f87835523720cdf5c',
};
const devMode = process.env.NODE_ENV === 'development';

export const getUrl = async (key: string): Promise<Url | null> => {
  const url = devMode ? apiDevUrl : apiProdUrl;
  const query = '?key=' + key;
  const urlResponse = await fetch(url + '/Itsy' + query, { headers })
    .then((response) => {
      if (!response.ok) return null;
      if (response.status !== 200) return null;
      return response.json() as unknown as Url;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
  return urlResponse;
};

export const putUrl = async (urlRequest: Url): Promise<Key | null> => {
  const url = devMode ? apiDevUrl : apiProdUrl;
  const answerResult = await fetch(url + '/Itsy', {
    body: JSON.stringify(urlRequest),
    headers: headers,
    method: 'put',
  })
    .then((response) => {
      if (!response.ok) return null;
      if (response.status !== 200 && response.status !== 201) return null;
      return response.json() as unknown as Key;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
  return answerResult;
};
