<script lang="ts" setup>
import { Url } from '@/interfaces/Url';
import { putUrl } from '@/services/ItsyApi';
import { createToast } from 'mosha-vue-toastify';
import { OrbitSpinner } from 'epic-spinners';
import { ref } from 'vue';

const devMode = process.env.NODE_ENV === 'development';
const shortenedUrl = ref('?');
const isBusy = ref(false);
let lastUrl = '';

// Shorten the requested URL
async function shortenUrl(): Promise<void> {
  const url =
    (document.getElementById('shorten') as HTMLInputElement)?.value ?? '';
  if (!url || url === '' || url === lastUrl) {
    return;
  }
  isBusy.value = true;
  lastUrl = url;
  // Call API to shorten passed in text
  const urlRequest: Url = {
    url: url,
  };
  const key = await putUrl(urlRequest);
  if (key) {
    const shortUrl = devMode
      ? 'http://localhost:8080/#/' + key.key
      : 'http://itsyurl.uk/#/' + key.key;
    shortenedUrl.value = shortUrl;
    createToast(`Created short URL (copied to clipboard): ${shortUrl}`, {
      position: 'top-center',
      type: 'info',
      showIcon: true,
      transition: 'slide',
    });
    if (navigator.clipboard) {
      setTimeout(() => {
        navigator.clipboard.writeText(shortUrl);
      }, 2000);
    }
  } else {
    createToast(`Failed to create short URL`, {
      position: 'top-center',
      type: 'warning',
      showIcon: true,
      transition: 'slide',
    });
  }
  isBusy.value = false;
}
</script>

<template>
  <OrbitSpinner
    v-if="isBusy"
    :animation-duration="2500"
    :size="100"
    color="orange"
    style="position: absolute; left: 46%; top: 45%; text-align: center"
  />
  <div class="grid grid-cols-10 gap-4">
    <div class="col-start-2 col-span-8 h-12 flex items-center justify-center">
      <h1 class="font-bold">
        Welcome to Itsy a simple and Ad free URL shortener
      </h1>
    </div>
    <div
      class="col-start-2 col-span-8 border-4 rounded-lg border-gray-700 h-48 flex flex-col items-center justify-center"
    >
      <form v-on:submit.prevent="shortenUrl" class="relative w-full">
        <fieldset :disabled="isBusy">
          <div class="flex flex-col">
            <label class="w-[95%] self-center mt-2">
              <input
                class="placeholder:italic placeholder:text-slate-800 block text-black bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                placeholder="Enter the URL to be shortened"
                type="text"
                name="shorten"
                id="shorten"
              />
            </label>
            <button
              class="w-[95%] self-center mt-4 bg-orange-500 hover:enabled:bg-orange-700 text-white font-bold py-2 px-4 rounded-full"
              type="submit"
            >
              Shorten
            </button>
          </div>
        </fieldset>
      </form>
      <div class="relative w-full mt-5">
        <span class="float-left ml-5"
          >Itsy URL:
          <a v-if="shortenedUrl != '?'" :href="shortenedUrl" target="_blank">{{
            shortenedUrl
          }}</a>
          <span v-else>?</span>
        </span>
      </div>
    </div>
    <div class="col-start-1 col-end-3 h-2 flex items-center justify-center">
      <!-- Space 1 -->
    </div>
    <div class="col-end-10 col-span-2 h-2 flex items-center justify-center">
      <!-- Space 2 -->
    </div>
    <div class="col-start-2 col-span-10 h-8 flex items-center justify-left">
      <span class="ml-1">©2024 ItsyUrl.uk v1.0.0</span>
    </div>
  </div>
</template>
